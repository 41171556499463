<template>
  <div class="counter-map">
    <h1>{{ header }}</h1>
  <gmap-map
      :center="center"
      :zoom="8"
      map-type-id="roadmap"
      background-color="#FFF"
      :options="mapOptions"
      style="width: 100%; height: 100%"
  >
    <gmap-cluster>
    <gmap-marker
        :key="index"
        v-for="(m, index) in coords"
        :position="m"
        :clickable="false"
        :draggable="false"
    />
    </gmap-cluster>
  </gmap-map>
  </div>
</template>

<script>

export default {
  name: "CounterMap",
  data() {
    return {
      center: {lat: 51.8076822, lng: 5.2984798},
      carousel: 0,
      coords: [],
      mapOptions: {
        backgroundColor: '#FFF',
        disableDefaultUI: true,
        scaleControl: false,
        scrollwheel: false,
        styles: [
          {
            "featureType": "road",
            "stylers": [
              { "visibility": "off" }
            ]
          },{
            "elementType": "labels",
            "stylers": [
              { "visibility": "off" }
            ]
          },
        ]
      },
    }
  },
  created() {
    this.updateCarousel();
    setInterval(() => {
      this.updateCarousel();
    }, 30000)
  },
  props: {
    userCoords: Array,
    dateCoords: Array,
  },
  methods: {
    updateCarousel() {
      this.carousel = this.carousel+1%2;
      if (this.carousel === 0) {
        this.coords = this.userCoords;
        this.header = 'Users online last 24 hours'
      } else {
        this.coords = this.dateCoords;
        this.header = 'Dates today'
      }
    }
  }
}
</script>

<style scoped>
  .counter-map {
    width: 100%;
    height: 100%;
  }
  h1 {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.4);
  }
</style>
<template>
  <div v-if="!loading">
    <section id="office-monitor" v-if="officeData != null">
      <b-row class="full-height">
        <b-col lg="6" class="p-2">
          <b-row className="match-height">
            <b-col
                lg="12"
                md="12"
            >
              <congratulations/>
            </b-col>
          </b-row>
          <b-row :class="{'hidden': (carousel !== 0)}">
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="secondary"
                  :statistic="officeData.online.total"
                  statistic-title="Online users yesterday"
                  :chart-data="getSeries(officeData.online.series, 'Average per week')"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="success"
                  :statistic="officeData.newUsers.total"
                  statistic-title="New users"
                  :chart-data="getSeries(officeData.newUsers.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="warning"
                  :statistic="officeData.active.total"
                  statistic-title="Active users"
                  :chart-data="getSeries(officeData.active.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="danger"
                  :statistic="officeData.churn.total"
                  statistic-title="Churned users"
                  :chart-data="getSeries(officeData.churn.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  :statistic="officeData.raters.total"
                  statistic-title="Total raters"
                  :chart-data="getSeries(officeData.raters.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="danger"
                  :statistic="officeData.cancels.total"
                  statistic-title="Cancels"
                  :chart-data="getSeries(officeData.cancels.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="info"
                  :statistic="officeData.matches.total"
                  statistic-title="Matches"
                  :chart-data="getSeries(officeData.matches.series)"
              />
            </b-col>
            <b-col
                lg="6"
                sm="6"
            >
              <statistic-card-with-area-chart
                  icon="UsersIcon"
                  color="success"
                  :statistic="officeData.dates.total"
                  statistic-title="Dates"
                  :chart-data="getSeries(officeData.dates.series)"
              />
            </b-col>
          </b-row>
          <b-row :class="{'hidden': (carousel !== 1), 'h-100': true}">
            <iframe width="100%" height="100%"
                    src="https://datastudio.google.com/embed/reporting/870a164f-340f-4004-946d-e8ebb05c67ae/page/AQejC"
                    frameborder="0" style="border:0" allowfullscreen></iframe>
          </b-row>
        </b-col>

        <b-col>
          <counter-map :user-coords="officeData.usersToday" :date-coords="officeData.datesToday"/>
        </b-col>
      </b-row>
    </section>
    <section id="password-screen" v-else>
      PassKey: <input v-model="passKey"/>
      <button class="btn btn-primary" @click="fetchData">Get data</button>
    </section>
  </div>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'

import {kFormatter} from '@core/utils/filter'
import Congratulations from "@/views/monitors/components/Congratulations";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import CounterMap from "@/views/monitors/components/CounterMap";

export default {
  components: {
    BRow,
    BCol,
    Congratulations,
    CounterMap,
    StatisticCardWithAreaChart
  },
  data() {
    return {
      loading: true,
      officeData: null,
      passKey: "",
      carousel: 0,
      carouselTimer: null,
    }
  },
  created() {
    this.passKey = localStorage.getItem('officeStats-passKey');
    this.fetchData();
    this.carouselTimer = setInterval(() => {
      this.carousel = ++this.carousel % 2;
    }, 30000)
  },
  methods: {
    kFormatter,
    fetchData() {
      // fetch(`http://localhost:9000/v1/stats/office`, {
      fetch(`https://api.breeze.dating/v1/stats/office`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'passKey': this.passKey})
      }).then(this.handleResponse).then((response) => {
        this.officeData = response.data;
        localStorage.setItem('officeStats-passKey', this.passKey);
        this.loading = false;
      }, () => { //Not allowed, so wrong passKey
        this.loading = false;
      });
    },
    handleResponse(response) {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            //Todo Show unauthorizes snackbar
          }
          if (response.status === 409) {
            return Promise.reject(data)
          }
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        return data;
      });
    },
    getSeries(series, label) {
      if (series == null) {
        return []
      }
      return [{
        name: label,
        data: series,
      }]
    }
  },
}
</script>

<style scoped>
#office-monitor {
  height: 100vh;
  width: 100vw;
}
</style>
